import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";

const JsonFormatPopover = ({ children }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        {`${JSON.stringify(children).substring(0, 10)}...`}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <Box p={2}>
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
            {typeof children === "object" ? JSON.stringify(children, null, 2) : children}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default JsonFormatPopover;
