import { http } from "../UppAuth";
import { ChangeLogType } from "./../screens/changelog/Changelog";

export interface ChangeLogItem {
  tenantId: string;
  changeType: "targetCpa" | "targetRoas" | "targetSpend" | "blocked" | "unblocked";
  previousValue: number | string;
  change: number | string | string[];
  user: string;
  userId: string;
  date: string;
}

export interface ChangeLogCount {
  blockedProduct: number;
  boostedProduct: number;
  category: number;
  lockedProduct: number;
  mpaUpload: number;
  productValueSet: number;
  budget: number;
  target: number;
  all: number;
  ignoreProductsCustomLabelMapping: number;
  uppWorkflow: number;
}

export async function getChangelogItems(
  offset: number,
  limit: number,
  from: Date,
  to: Date,
  changeLogType: ChangeLogType
): Promise<ChangeLogItem[]> {
  const fromParam = from ? `from=${dateTimeZoneAdjusted(from)}&` : "";
  const toParam = to ? `to=${dateTimeZoneAdjusted(to)}&` : "";
  const offsetParam = offset ? `offset=${offset}&` : "";
  const limitParam = limit ? `limit=${limit}` : "";

  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/changelog/?${fromParam}${toParam}${offsetParam}${limitParam}&type=${changeLogType}`
  );

  if (result.status === 200) {
    return result.data;
  }
  return [];
}
export async function getAllChangelogItems(from: Date, to: Date, changeLogType: string): Promise<ChangeLogItem[]> {
  const fromParam = from ? `from=${dateTimeZoneAdjusted(from)}&` : "";
  const toParam = to ? `to=${dateTimeZoneAdjusted(to)}&` : "";
  const typeParam = changeLogType ? `type=${changeLogType}&` : "";

  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/changelog/all?${fromParam}${toParam}${typeParam}`
  );

  if (result.status === 200) {
    return result.data;
  }
  return [];
}

export async function getChangelogCounts(from: Date, to: Date): Promise<ChangeLogCount> {
  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/changelog/count/?from=${dateTimeZoneAdjusted(
      from
    )}&to=${dateTimeZoneAdjusted(to)}`
  );
  if (result.status === 200) {
    return result.data;
  }
  return {
    blockedProduct: 0,
    boostedProduct: 0,
    category: 0,
    lockedProduct: 0,
    mpaUpload: 0,
    productValueSet: 0,
    budget: 0,
    target: 0,
    all: 0,
    ignoreProductsCustomLabelMapping: 0,
    uppWorkflow: 0,
  };
}

export function dateTimeZoneAdjusted(date: Date): string {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}
