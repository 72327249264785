import { ThemeProvider } from "@emotion/react";
import { AppBar, Toolbar } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Route, Link as RouterLink, Routes } from "react-router-dom";
import styled from "styled-components";
import "./App.css";
import LogoutButton from "./components/LogoutButton";
import LeftNavBar from "./components/NavBar/LeftNavBar";
import logo from "./images/upp-blue-logo.svg";
import AccountsOverview from "./screens/accountsOverview/AccountsOverview";
import AccountsAudit from "./screens/accountsAudit/AccountsAudit";
import Audit from "./screens/audit/Audit";
import BlockedProducts from "./screens/blockedProducts/BlockedProducts";
import Campaign from "./screens/campaign/Campaign";
import CampaignAutomation from "./screens/campaignAutomation/CampaignAutomation";
import CampaignMonitoring from "./screens/campaignMonitoring/CampaignMonitoring";
import Changelog from "./screens/changelog/Changelog";
import Dashboard from "./screens/dashboard/Dashboard";
import DataManagement from "./screens/dataManagement/DataManagement";
import DatasourceConfiguration from "./screens/datasources/Datasources";
import { JobScheduler } from "./screens/jobScheduler/JobScheduler";
import JobSchedulerOverview from "./screens/jobSchedulerOverview/JobSchedulerOverview";
import { OnboardingWizard } from "./screens/onboardingWizard/OnboardingWizard";
import { WizardProvider } from "./screens/onboardingWizard/WizardProvider";
import TenantConfig from "./screens/tenantConfig/TenantConfig";

import AccountState from "./screens/accountState/AccountState";
import ChatbotHistory from "./screens/chatbotHistory/ChatbotHistory";
import MigrationForm from "./screens/marketing/tabPages/CognismMigration";
import MarketingTabs from "./screens/marketing/tabPages/MarketingTabs";
import ActionsEmailsMetrics from "./screens/stateActionsEmailMetrics/StateActionsEmailMetrics";
import UsefulLinks from "./screens/usefulLinks/UsefulLinks";
import UserManagement from "./screens/userManagement/UserManagement";
import "./styles/index.css";
import { uppColourPalette } from "./util/colours";
import { theme } from "./util/theme";
import ProductFeatures from "./screens/productFeatures/ProductFeatures";
import InfluentialProducts from "./screens/influentialProducts/InfluentialProducts";
import TemplateSavedSearch from "./screens/templateSearch/TemplateSavedSearch";
const StyledAppBar = styled(AppBar)`
  color: white;
  position: absolute !important;
`;

const StyledToolbar = styled(Toolbar)`
  background-color: ${uppColourPalette.darkGreen[500]};
`;

const StyledLeftNavBar = styled(LeftNavBar)`
  height: calc(100% - 64px);
  background-color: rgb(255, 255, 255);
  z-index: 1200;
  outline: 0px;
  left: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 240px;
  top: 64px;
`;

const StyledLogo = styled.img`
  height: 24px;
  width: auto;
`;

const MainLayoutContainer = styled.div`
  background: #f4f4f9;
  display: grid;
  grid-template-columns: 240px 1fr;
`;

const queryClient = new QueryClient();
LicenseInfo.setLicenseKey(
  "cc740b017b19383ae1030b1edb8ba8f3Tz00Mzg0OCxFPTE2ODQzOTgwOTAzODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  return (
    <WizardProvider>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <ConfirmProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
              <ThemeProvider theme={theme}>
                <div className="App">
                  <StyledAppBar elevation={0}>
                    <StyledToolbar>
                      <RouterLink to="/Dashboard">
                        <StyledLogo src={logo} alt="the upp logo" />
                      </RouterLink>
                      <LogoutButton />
                    </StyledToolbar>
                  </StyledAppBar>
                  <MainLayoutContainer>
                    <StyledLeftNavBar />
                    <ScrollBar>
                      <Routes>
                        <Route path="/" element=<Dashboard /> />
                        <Route path="/TenantConfiguration" element=<TenantConfig /> />
                        <Route path="/AccountState" element=<AccountState /> />
                        <Route path="/OnboardingWizard" element=<OnboardingWizard /> />
                        <Route path="/Campaigns" element=<Campaign /> />
                        <Route path="/ActionsEmailsMetrics" element=<ActionsEmailsMetrics /> />
                        <Route path="/Dashboard" element=<Dashboard /> />
                        <Route path="/DataManagement" element=<DataManagement /> />
                        <Route path="/JobScheduler" element=<JobScheduler /> />
                        <Route path="/CampaignAutomation" element=<CampaignAutomation /> />
                        <Route path="/UsefulLinks" element=<UsefulLinks /> />
                        <Route path="/Changelog" element=<Changelog /> />
                        <Route path="/Datasources" element=<DatasourceConfiguration /> />
                        <Route path="/DatasourceConfiguration" element=<DatasourceConfiguration /> />
                        <Route path="/Audit" element=<Audit /> />
                        <Route path="/AccountsOverview" element=<AccountsOverview /> />
                        <Route path="/AccountsAudit" element=<AccountsAudit /> />
                        <Route path="/CampaignMonitoring" element=<CampaignMonitoring /> />
                        <Route path="/BlockedProducts" element=<BlockedProducts /> />
                        <Route path="/InfluentialProducts" element=<InfluentialProducts /> />
                        <Route path="/UserManagment" element=<UserManagement /> />
                        <Route path="/JobSchedulerOverview" element=<JobSchedulerOverview /> />
                        <Route path="/CognismMigration" element=<MigrationForm /> />
                        <Route path="/Marketing" element=<MarketingTabs /> />
                        <Route path="/ChatbotHistory" element=<ChatbotHistory /> />
                        <Route path="/ProductFeatures" element=<ProductFeatures /> />
                        <Route
                          path="/TemplateSearch"
                          element=<TemplateSavedSearch searchName={""} createdByName={""} isFavorite={false} />
                        />
                      </Routes>
                    </ScrollBar>
                    <ReactQueryDevtools position="bottom-right" />
                  </MainLayoutContainer>
                </div>
              </ThemeProvider>
            </LocalizationProvider>
          </ConfirmProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </WizardProvider>
  );
}

export default App;
