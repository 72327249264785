import { TemplateSearch } from "../screens/templateSearch/templateSearch";
import { http } from "../UppAuth";

export const getTemplateSavedSearches = async () => {
  const response = await http.get<TemplateSearch[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/templateSearches`
  );
  return response.data;
};

export const saveTemplateSavedSearch = async (templateSavedSearch: TemplateSearch) => {
  const response = await http.post<TemplateSearch>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/templateSearches`,
    templateSavedSearch
  );
  return response.data;
};

export const deleteTemplateSavedSearch = async (_id: string) => {
  const result = await http.delete<TemplateSearch>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/templateSearches/${_id}`
  );
  if (result.status !== 200) {
    console.log("Failed to delete template search");
    return false;
  }
  return true;
};

export const updateTemplateSavedSearch = async (templateSavedSearch: TemplateSearch) => {
  const result = await http.put<TemplateSearch>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/templateSearches/${templateSavedSearch._id}`,
    templateSavedSearch
  );

  if (result.status !== 200) {
    console.log("Failed to update template search");
    return false;
  }
  return true;
};
