import React, { useEffect, useState } from "react";
import { GridSortDirection, TemplateSearch } from "./templateSearch";
import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";
import { RadioSelect } from "./RadioSort";
import ColumnSelector from "./ColumnsSelect";
import {
  getTemplateSavedSearches,
  saveTemplateSavedSearch,
  updateTemplateSavedSearch,
} from "../../api/templateSavedSearch";
import DateRangeSelector from "./PresetDate";
import Dropdown from "./Dropdown";
import { filterOperators, Operator } from "../../util/templateSearch";
import { DateOptions } from "../../types/dateOptions";
import { Option } from "./TemplateSavedSearch";

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  min-width: 500px;
  height: 450px;
  border: 1px solid ${uppColourPalette.navy[500]};
  position: absolute;
  background-color: #fff;
  top: 10%;
`;

export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 100px;
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
`;

export const Title = styled.label`
  margin: 8px 0;
  font-size: 1rem;
  color: ${uppColourPalette.navy[300]};
`;
export const TitleAndDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const FormButton = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  color: ${uppColourPalette.navy[300]};
  border: none;
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  right: 0;

  &:hover {
    background-color: ${uppColourPalette.grey[100]};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelAndColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const ContainerRadioAndColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 8px;
  gap: 8px;
`;

const TitleAndCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface ReportFormProps {
  onClose: () => void;
  onSave: (report: TemplateSearch) => void;
  setTemplate: (value: React.SetStateAction<TemplateSearch>) => void;
  template: TemplateSearch;
  isUpdateReport?: boolean;
  sortOptions: Option[];
  filterOptions: Option[];
  selectedComparisonColumns: Option[];
  comparisonMode: boolean;
  setComparisonMode: (value: React.SetStateAction<boolean>) => void;
  selectedColumns: string[];
  setSelectedColumns: (value: React.SetStateAction<string[]>) => void;
  handleSelectComparisonColumn: (value: string[]) => void;
}

const ReportForm: React.FC<ReportFormProps> = ({
  onClose,
  onSave,
  template,
  setTemplate,
  sortOptions,
  filterOptions,
  isUpdateReport = false,
  comparisonMode,
  setComparisonMode,
  selectedColumns,
  setSelectedColumns,
}) => {
  const [currentSortModel, setCurrentSortModel] = useState<GridSortDirection>("asc");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterOperator, setFilterOperator] = useState<string>(Operator.Contains);
  const [filterValue, setFilterValue] = useState<string>("");
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [presetDateRange, setPresetDateRange] = useState<DateOptions>(
    template.presetDateRange ?? DateOptions.LAST_7_DAYS
  );
  const [presetComparisonDateRange, setPresetComparisonDateRange] = useState<DateOptions>(
    template.presetComparisonDateRange ?? DateOptions.PREVIOUS_PERIOD
  );
  useEffect(() => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      presetDateRange,
      presetComparisonDateRange,
    }));
  }, [presetDateRange, presetComparisonDateRange, setTemplate]);

  const handlePresetDateRangeChange = (newDateRange: DateOptions) => {
    setPresetDateRange(newDateRange ?? DateOptions.LAST_7_DAYS);
  };

  const handlePresetComparisonDateRangeChange = (newComparisonDateRange: DateOptions) => {
    setPresetComparisonDateRange(newComparisonDateRange ?? DateOptions.PREVIOUS_PERIOD);
  };

  const handleCloseModal = () => {
    setCloseModal(true);
  };

  useEffect(() => {
    if (closeModal) {
      onClose();
    }
  }, [closeModal, onClose]);

  useEffect(() => {
    if (isUpdateReport && template) {
      if (template.sortModel && template.sortModel.length && template.sortModel[0].field) {
        setCurrentSortModel(template.sortModel[0].sort);
        setSortColumn(template.sortModel[0].field);
      }

      if (template.filterModel && template.filterModel.items && template.filterModel.items.length) {
        setFilterColumn(template.filterModel.items[0].field);
        setFilterOperator(template.filterModel.items[0].operator);
        setFilterValue(template.filterModel.items[0].value);
      }

      if (template.comparisonMode) {
        setComparisonMode(template.comparisonMode);
      }
      if (template.columns) {
        setSelectedColumns(template.columns);
      }
    }
  }, [template, isUpdateReport]);

  const handleSortModel = (value: string) => {
    setCurrentSortModel(value as GridSortDirection);
  };

  useEffect(() => {
    if (sortColumn) {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        sortModel: [
          {
            field: sortColumn,
            sort: currentSortModel,
          },
        ],
      }));
    }
  }, [sortColumn, currentSortModel]);

  useEffect(() => {
    if (filterColumn) {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        filterModel: {
          items: [
            {
              field: filterColumn,
              operator: filterOperator,
              id: "",
              value: filterValue,
            },
          ],
          logicOperator: "and" as any,
          quickFilterValues: [],
          quickFilterLogicOperator: "and" as any,
        },
      }));
    }
  }, [filterColumn, filterOperator, filterValue]);

  useEffect(() => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      comparisonMode: comparisonMode,
    }));
  }, [comparisonMode]);

  const handleColumnsChange = (columns: string[]) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      columns: columns,
    }));
  };

  const handleSaveAndUpdate = async () => {
    if (!isUpdateReport) {
      try {
        await saveTemplateSavedSearch(template);
        const getAllReports = await getTemplateSavedSearches();
        onSave(getAllReports.find((report) => report.searchName === template.searchName) as TemplateSearch);
        onClose();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await updateTemplateSavedSearch(template);
        onSave(template);
        onClose();
        alert("Template updated successfully");
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <TemplateContainer>
        <CloseModalButton onClick={handleCloseModal}>X</CloseModalButton>
        {isUpdateReport ? <h3>Update Template</h3> : <h3>Create Template</h3>}
        <ContainerTitles>
          <LabelAndColumn>
            <Title>
              Template Name:
              <input
                style={{ marginLeft: "5px", padding: "4px", height: "35px", minWidth: "252px" }}
                type="text"
                value={template.searchName}
                onChange={(e) => setTemplate({ ...template, searchName: e.target.value })}
              />
            </Title>
          </LabelAndColumn>
          <LabelAndColumn>
            <Title>Columns:</Title>
            <ColumnSelector
              columns={template.columns}
              comparisonMode={comparisonMode}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              onChange={handleColumnsChange}
            />
          </LabelAndColumn>
          <TitleAndCheckbox>
            <Title>Comparison mode:</Title>
            <input
              type="checkbox"
              checked={template.comparisonMode}
              onChange={(e) => {
                setTemplate({ ...template, comparisonMode: e.target.checked });
                setComparisonMode(e.target.checked);
              }}
            />
          </TitleAndCheckbox>
          <DateRangeSelector
            title="Select Target "
            options={Object.values(DateOptions).slice(0, 11)}
            onChange={handlePresetDateRangeChange}
            value={template.presetDateRange ?? DateOptions.LAST_7_DAYS}
          />
          {template.comparisonMode && (
            <DateRangeSelector
              title="Select Comparison "
              options={[DateOptions.PREVIOUS_PERIOD, DateOptions.PREVIOUS_YEAR]}
              onChange={handlePresetComparisonDateRangeChange}
              value={template.presetComparisonDateRange ?? DateOptions.PREVIOUS_PERIOD}
            />
          )}
          <TitleAndDropdown>
            <Title>Sort Columns:</Title>
            <Dropdown selected={sortColumn} options={sortOptions} handleSelection={setSortColumn} />
            <ContainerRadioAndColumn>
              <RadioSelect
                options={["asc", "desc"]}
                onChange={handleSortModel}
                defaultValue={currentSortModel as string}
              />
            </ContainerRadioAndColumn>
          </TitleAndDropdown>

          <TitleAndCheckbox>
            <Title>Filter:</Title>
            <Dropdown selected={filterColumn} options={filterOptions} handleSelection={setFilterColumn} />
            <Dropdown selected={filterOperator} options={filterOperators} handleSelection={setFilterOperator} />
            <input
              type="text"
              placeholder="Value"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </TitleAndCheckbox>
          <ButtonContainer>
            <FormButton onClick={handleSaveAndUpdate}>{isUpdateReport ? "Update" : "Save"}</FormButton>{" "}
          </ButtonContainer>
        </ContainerTitles>
      </TemplateContainer>
    </>
  );
};

export default ReportForm;
