export enum DateOptions {
  TODAY = "today",
  YESTERDAY = "yesterday",
  THIS_WEEK_MT = "thisWeekMT",
  THIS_WEEK_ST = "thisWeekST",
  LAST_WEEK_SUN_TO_SAT = "lastWeekSunToSat",
  LAST_WEEK_MON_TO_SUN = "lastWeekMonToSun",
  LAST_7_DAYS = "last7Days",
  LAST_14_DAYS = "last14Days",
  THIS_MONTH = "thisMonth",
  LAST_30_DAYS = "last30Days",
  LAST_MONTH = "lastMonth",
  PREVIOUS_PERIOD = "previousPeriod",
  PREVIOUS_YEAR = "previousYear",
}
