import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Heading } from "../../components/Heading";
import { Box, Divider, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { DateRange } from "@mui/x-date-pickers-pro";
import { SetStateAction, useState } from "react";
import { StyledButtonFilled } from "../../components/StyledButton";
import styled from "@emotion/styled";
import { checkMapping } from "../../api/health";
import { InfoRounded } from "@material-ui/icons";

const StyledDiv = styled.div`
  margin-top: 20px;
  width: 300px;
`;
const MappingChecker = () => {
  const [dateRange, setDateRange] = useState<DateRange<DateTime>>([
    DateTime.now().minus({ days: 8 }).startOf("day"),
    DateTime.now().minus({ days: 1 }).endOf("day"),
  ]);
  const [isMapped, setIsMapped] = useState<boolean>();

  const checkMappingResult = async (startDate: string, endDate: string) => {
    const result = await checkMapping(startDate, endDate);
    setIsMapped(result);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>
          Check Mapping
          <Tooltip
            title={
              "The Check Mapping feature ensures that Ad Spend (from Google Ads) and Revenue (from order data) are both present during a given period.\nIf no SKUs have both cost and revenue data for that timeframe, the mapping fails, indicating a mapping issue"
            }
          >
            <InfoRounded color="primary" />
          </Tooltip>
        </Heading>
        <Divider />

        <StyledDiv>
          <DateRangePicker
            defaultValue={dateRange}
            value={dateRange}
            onChange={(newValue: SetStateAction<DateRange<DateTime>>) => {
              setDateRange(newValue);
            }}
          />
          <br></br>
          <StyledButtonFilled onClick={() => checkMappingResult(dateRange[0]!.toISODate(), dateRange[1]!.toISODate())}>
            Check Mapping
          </StyledButtonFilled>
          <div>
            {isMapped !== undefined && (isMapped ? <p>Mapping is correct ✅</p> : <p>Mapping is incorrect ❌</p>)}
          </div>
        </StyledDiv>
      </Box>
    </>
  );
};

export default MappingChecker;
