import { DateOptions } from "../../types/dateOptions";
import { useState } from "react";
import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";

export const DateRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${uppColourPalette.navy[300]};
`;

export const TitleDateRange = styled.p`
  margin: 8px 0;
  font-size: 1rem;
  color: ${uppColourPalette.navy[300]};
`;

interface DateRangeSelectorProps {
  onChange: (presetDateRange: DateOptions) => void;
  options: DateOptions[];
  title: string;
  value: DateOptions;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ onChange, options, title, value }) => {
  const [selectedOption, setSelectedOption] = useState<DateOptions | null>(null);

  const handleSelection = (option: DateOptions) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <DateRangeContainer>
      <TitleDateRange>{title}</TitleDateRange>
      <select
        style={{ marginLeft: "4px", padding: "4px", width: "auto" }}
        value={selectedOption || value}
        onChange={(e) => handleSelection(e.target.value as DateOptions)}
      >
        <option value="" disabled></option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </DateRangeContainer>
  );
};

export default DateRangeSelector;
