import React, { useState } from "react";
import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";
import ClickOutsideDetector from "./ClickOutsideDetector";
import { availableColumnGroups } from "../../util/templateSearch";

export const StyledSelect = styled.div<{ minWidth?: string }>`
  appearance: none;
  border: 2px solid ${uppColourPalette.navy[400]};
  border-radius: 4px;
  padding: 4px;
  height: 35px;
  outline: none;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "300px")};
  color: ${uppColourPalette.navy[400]};
  text-indent: 0;
  text-align: left;
  font-size: 0.75rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const VisibleMetrics = styled.div`
  color: ${uppColourPalette.navy[500]};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
`;

interface StyledDropdownProps {
  dropDownPosition: "top" | "bottom";
}

export const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  bottom: ${(props) => (props.dropDownPosition === "top" ? "155px" : "auto")};
  top: ${(props) => (props.dropDownPosition === "bottom" ? "155px" : "auto")};
  margin: 0;
  padding-top: 8px;
  padding-bottom: 10px;
  width: 200px;
  overflow-y: scroll;
  height: 260px;
  max-width: auto;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 100;
`;

const Checkbox = styled.input`
  margin-right: 4px;
`;

const GroupHeader = styled.div`
  font-weight: bold;
  color: ${uppColourPalette.navy[400]};
  padding: 8px 4px;
  text-align: left;
  cursor: pointer;
`;

const ColumnItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  color: ${uppColourPalette.navy[500]};
  height: 40px;
  text-align: left;
`;

const CloseButton = styled.button`
  margin-left: 2px;
  cursor: pointer;
  color: ${uppColourPalette.navy[400]};
  font-size: 0.75rem;
  background-color: transparent;
  border: none;
`;

interface ColumnSelectorProps {
  columns: string[];
  comparisonMode: boolean;
  selectedColumns: string[];
  setSelectedColumns: (value: React.SetStateAction<string[]>) => void;
  onChange: (columns: string[]) => void;
}

const ColumnSelector = ({ selectedColumns, setSelectedColumns, onChange }: ColumnSelectorProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({});

  const MAX_VISIBLE_METRICS = 3;

  const handleToggleGroup = (groupName: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleSelectColumn = (column: string) => {
    if (!selectedColumns.includes(column)) {
      setSelectedColumns((prevColumns) => [...prevColumns, column]);
      onChange([...selectedColumns, column]);
    } else {
      setSelectedColumns((prevColumns) => prevColumns.filter((col) => col !== column));
      onChange(selectedColumns.filter((col) => col !== column));
    }
  };

  const handleRemoveColumn = (column: string) => {
    setSelectedColumns((prevColumns) => prevColumns.filter((col) => col !== column));
  };

  return (
    <ClickOutsideDetector
      onClickOutside={() => {
        setIsDropdownOpen(false);
      }}
    >
      <StyledSelect onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {selectedColumns.slice(0, MAX_VISIBLE_METRICS).map((column) => (
          <VisibleMetrics key={column}>
            {column}
            <CloseButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveColumn(column);
              }}
            >
              &times;
            </CloseButton>
          </VisibleMetrics>
        ))}
        {selectedColumns.length > MAX_VISIBLE_METRICS && (
          <VisibleMetrics>+{selectedColumns.length - MAX_VISIBLE_METRICS}</VisibleMetrics>
        )}
      </StyledSelect>

      {isDropdownOpen && (
        <StyledDropdown dropDownPosition="bottom">
          {availableColumnGroups.map((group) => (
            <div key={group.group}>
              <GroupHeader onClick={() => handleToggleGroup(group.group)}>{group.group}</GroupHeader>
              {openGroups[group.group] &&
                group.columns.map((column) => (
                  <ColumnItem key={column.value} onClick={() => handleSelectColumn(column.value)}>
                    <Checkbox type="checkbox" checked={selectedColumns.includes(column.value)} />
                    {column.displayValue}
                  </ColumnItem>
                ))}
            </div>
          ))}
        </StyledDropdown>
      )}
    </ClickOutsideDetector>
  );
};

export default ColumnSelector;
