import { BackOfficeErrorCode } from "../types/errors";
import { http } from "../UppAuth";

export interface PGAOverview {
  tenantId: string;
  lastSuccessfulPGA: string | null;
}

export async function getPGAOverview(): Promise<Array<PGAOverview>> {
  const url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/accounts-overview/pga`;
  try {
    const result = await http.get<Array<PGAOverview>>(url);
    return result.data;
  } catch (errorResponse) {
    console.log(
      {
        message: `Unexpected error with ${url}`,
        errorCode: BackOfficeErrorCode.Unexpected,
      },
      errorResponse
    );
    return [];
  }
}

export enum GoogleCampaignType {
  Unknown = "Unknown",
  PerformanceMax = "PerformanceMax",
  SmartShoppingCampaign = "SmartShoppingCampaign",
}

export interface TenantCampaignType {
  tenantId: string;
  campaignType: GoogleCampaignType;
}

export async function getGoogleCampaignsOverview(): Promise<Array<TenantCampaignType>> {
  const url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/accounts-overview/google-campaigns`;
  try {
    const result = await http.get<Array<TenantCampaignType>>(url);
    return result.data;
  } catch (errorResponse) {
    console.log(
      {
        message: `Unexpected error with ${url}`,
        errorCode: BackOfficeErrorCode.Unexpected,
      },
      errorResponse
    );
    return [];
  }
}

//TODO rename?
export interface Comparison {
  metric: string;
  auditValue: number;
  externalValue: number;
  externalValuePercentDifference: number;
  externalValueSource: string;
}

export interface AuditHealth {
  fromDate: Date;
  toDate: Date;
  accountLevel: Comparison[];
  groupIdLevel: [];
}

export interface MappingChecker {
  hasMappedData: boolean;
}

export async function getAuditHealthReport(tenantId: string): Promise<AuditHealth> {
  const url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/account/${tenantId}/audit-health`;
  const result = await http.get<AuditHealth>(url);
  return result.data;
}

export async function checkMapping(from: string, to: string): Promise<boolean> {
  const url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/mapping-check?from=${from}&to=${to}`;
  const result = await http.get<MappingChecker>(url);
  return result.data.hasMappedData;
}
