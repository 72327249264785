import { uppColourPalette } from "../../util/colours";
import styled from "@emotion/styled";
import ReportForm from "./ReportForm";
import { useEffect, useState } from "react";
import { TemplateSearch } from "./templateSearch";
import { Box } from "@material-ui/core";
import ClickOutsideDetector from "./ClickOutsideDetector";
import { deleteTemplateSavedSearch, getTemplateSavedSearches } from "../../api/templateSavedSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { availableColumnGroups } from "../../util/templateSearch";

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const ContainerListReports = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SavedSearchContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  min-height: 180px;
  justify-content: center;
`;

export const Title = styled.p`
  margin: 8px 0;
  font-size: 1rem;
  color: #fff;
`;

export const Subtitle = styled.p`
  margin: 4px 0;
  font-weight: 300;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClickableIcon = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  display: inline-block;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonStyle = styled.button`
  background-color: transparent;
  color: ${uppColourPalette.navy[500]};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  font-size: 0.1rem;
`;

export const TableStyle = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableContent = styled.td`
  border: 1px solid #ccc;
  padding: 8px;
`;

export const TableHeader = styled.th`
  border: 1px solid #ccc;
  padding: 8px;
`;

interface CardProps {
  children: React.ReactNode;
  backgroundColour: string;
  foregroundColour?: string;
  border?: string;
  width?: number;
  height?: number;
  padding?: string;
}

export const Container = styled.div<{
  $backgroundColour: string;
  $foregroundColour?: string;
  $width?: number;
  $height?: number;
  $border?: string;
  $padding?: string;
}>`
  background-color: ${(props) => props.$backgroundColour};
  color: ${(props) => props.$foregroundColour || "white"};
  border: 1px solid ${(props) => props.$border || "black"};
  width: ${(props) => props.$width}px;
  height: auto;
  border-radius: 4px;
  padding: ${(props) => props.$padding || "6px 8px 8px 6px"};
  display: inline-block;
`;

const Card = ({ children, backgroundColour, foregroundColour, border, width, height, padding }: CardProps) => (
  <Container
    $backgroundColour={backgroundColour}
    $foregroundColour={foregroundColour}
    $border={border}
    $width={width}
    $height={height}
    $padding={padding}
    data-testid="card-container"
  >
    {children}
  </Container>
);

interface BaseSavedSearchCardProps {
  children: React.ReactNode;
  border?: string;
  padding?: string;
}

const BaseSavedSearchCard: React.FC<BaseSavedSearchCardProps> = ({ children, border, padding }) => {
  return (
    <Card
      backgroundColour={uppColourPalette.navy[500]}
      foregroundColour={uppColourPalette.navy[500]}
      border={uppColourPalette.navy[500]}
      padding={padding}
    >
      {children}
    </Card>
  );
};

export interface SavedSearchCardProps {
  searchName: string;
  createdByName: string;
  dateCreated?: string;
  isFavorite: boolean;
  onFavoriteClick?: () => void;
  onDeleteClick?: () => void;
}

const initialTemplateSearch: TemplateSearch = {
  _id: "",
  searchName: "",
  filterModel: { items: [] },
  sortModel: [],
  columns: [],
  categoryKey: "",
  isFavorite: false,
  createdOn: new Date(),
};
export interface Option {
  displayValue: string;
  value: string;
}

const TemplateSavedSearch = ({ dateCreated, isFavorite }: SavedSearchCardProps) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [savedReports, setSavedReports] = useState<TemplateSearch[]>([]);
  const [isUpdateReport, setIsUpdateReport] = useState(false);
  const [template, setTemplate] = useState<TemplateSearch>(initialTemplateSearch);
  const [options, setOptions] = useState<{ sort: Option[]; filter: Option[] }>({ sort: [], filter: [] });
  const [selectedComparisonColumns, setSelectedComparisonColumns] = useState<Option[]>([]);
  const [comparisonMode, setComparisonMode] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(template.columns);

  const handleSelectComparisonColumn = (columns: string[]) => {
    const flattenFilterableColumns = availableColumnGroups.flatMap((g) => g.filterableColumns);

    const derivedColumns = comparisonMode
      ? columns.flatMap((column) =>
          flattenFilterableColumns.filter((c) => c.value.startsWith(column) && !columns.includes(c.value))
        )
      : [];

    updateOptions(columns, derivedColumns);
    setSelectedComparisonColumns(comparisonMode ? derivedColumns : []);
  };

  const handleSaveReport = (report: TemplateSearch) => {
    setSavedReports((prevReports) => {
      const reportExists = prevReports.some((r) => r._id === report._id);
      return reportExists ? prevReports.map((r) => (r._id === report._id ? report : r)) : [...prevReports, report];
    });
    clearForm();
    setIsFormOpen(false);
    setIsUpdateReport(false);
  };

  const handleEdit = (report: TemplateSearch) => {
    setIsUpdateReport(true);
    setIsFormOpen(true);
    setTemplate(report);
  };
  useEffect(() => {
    const getAllReports = async () => {
      const reports = await getTemplateSavedSearches();
      setSavedReports(reports);
    };

    getAllReports();
  }, []);

  const deleteReport = async (_id: string) => {
    if (!window.confirm("Are you sure you want to delete this report?")) return;

    try {
      await deleteTemplateSavedSearch(_id);
      setSavedReports((prevReports) => prevReports.filter((report) => report._id !== _id));
      alert("Report deleted successfully");
    } catch (error) {
      console.error("Error deleting report:", error);
      alert("Failed to delete the report. Please try again.");
    }
  };

  useEffect(() => {
    handleSelectComparisonColumn(template.columns);
  }, [template]);

  useEffect(() => {
    handleSelectComparisonColumn(selectedColumns);
    setTemplate((prev) => ({ ...prev, columns: selectedColumns }));
  }, [selectedColumns]);

  const clearForm = () => {
    setTemplate(initialTemplateSearch);
    setOptions({ sort: [], filter: [] });
    setSelectedComparisonColumns([]);
    setSelectedColumns([]);
    setComparisonMode(false);
  };

  const updateOptions = (columns: string[], derivedColumns: Option[] = []) => {
    const sortHandled = availableColumnGroups
      .flatMap((g) => g.sortableColumns)
      .filter((c) => columns.includes(c.value));

    const filterHandled = availableColumnGroups
      .flatMap((g) => g.filterableColumns)
      .filter((c) => columns.includes(c.value));

    const mergedColumns = [...new Set([...derivedColumns])];

    setOptions({
      sort: comparisonMode ? [...new Set([...sortHandled, ...mergedColumns])] : sortHandled,
      filter: comparisonMode ? [...new Set([...filterHandled, ...mergedColumns])] : filterHandled,
    });
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
        height: "100%",
      }}
    >
      <ClickOutsideDetector onClickOutside={() => setIsFormOpen(false)}>
        <TemplateContainer>
          <BaseSavedSearchCard border={isFavorite ? uppColourPalette.navy[500] : undefined}>
            <SavedSearchContent>
              <Title>Create Report</Title>
              <button
                onClick={() => {
                  clearForm();
                  setIsFormOpen(true);
                  setIsUpdateReport(false);
                }}
              >
                +
              </button>
              {dateCreated && <Subtitle>{dateCreated}</Subtitle>}
            </SavedSearchContent>
          </BaseSavedSearchCard>
          <h3>All Reports</h3>
          {isFormOpen && (
            <ReportForm
              onClose={() => {
                setIsFormOpen(false);
                setIsUpdateReport(false);
              }}
              onSave={handleSaveReport}
              template={template}
              setTemplate={setTemplate}
              sortOptions={options.sort}
              filterOptions={options.filter}
              isUpdateReport={isUpdateReport}
              selectedComparisonColumns={selectedComparisonColumns}
              setComparisonMode={setComparisonMode}
              comparisonMode={comparisonMode}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              handleSelectComparisonColumn={handleSelectComparisonColumn}
            />
          )}
          <TableStyle>
            <thead>
              <tr>
                <TableHeader>Report Name</TableHeader>
                <TableHeader>Created On</TableHeader>
                <TableHeader>Edit/Remove</TableHeader>
              </tr>
            </thead>
            <tbody>
              {savedReports.map((report) => (
                <tr key={report._id}>
                  <TableContent>{report.searchName}</TableContent>
                  <TableContent>
                    {report.createdOn ? new Date(report.createdOn).toLocaleDateString() : "N/A"}
                  </TableContent>
                  <TableContent>
                    <ButtonStyle onClick={() => handleEdit(report)}>
                      <EditIcon fontSize="small" />
                    </ButtonStyle>
                    <ButtonStyle onClick={() => deleteReport(report._id)}>
                      <DeleteIcon fontSize="small" />
                    </ButtonStyle>
                  </TableContent>
                </tr>
              ))}
            </tbody>
          </TableStyle>
        </TemplateContainer>
      </ClickOutsideDetector>
    </Box>
  );
};

export default TemplateSavedSearch;
