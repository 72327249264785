import { forwardRef, useEffect, useRef } from "react";

interface ClickOutsideDetectorProps {
  onClickOutside: () => void;
  children: React.ReactNode;
  // Used to exclude a specific element from the click outside event (e.g. the button that toggles a modal)
  excludedElementRef?: React.RefObject<HTMLDivElement>;
}

const ClickOutsideDetector = ({ onClickOutside, children, excludedElementRef }: ClickOutsideDetectorProps) => {
  const container = useRef<HTMLDivElement>(null);

  const onKeyUp = (e: KeyboardEvent) => {
    const ESCAPE_KEY = 27;
    // For newer browsers
    if (e.key !== undefined && e.key === "Escape") {
      onClickOutside();
      // For older browsers
    } else if (e.keyCode !== undefined && e.keyCode === ESCAPE_KEY) {
      onClickOutside();
    }
  };

  const handleEvent = (e: MouseEvent | TouchEvent) => {
    if (!container.current) return;
    if (container.current.contains(e.target as Node)) return;

    if (excludedElementRef?.current?.contains(e.target as Node)) return;

    onClickOutside();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleEvent, false);
    document.addEventListener("touchend", handleEvent, false);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      // Remove listeners
      document.removeEventListener("mousedown", handleEvent, false);
      document.removeEventListener("touchend", handleEvent, false);
      document.removeEventListener("keyup", onKeyUp);
    };
  });

  return <div ref={container}>{children}</div>;
};

export default forwardRef(ClickOutsideDetector);
