import styled from "@emotion/styled";
import { uppColourPalette } from "../../util/colours";

export const RadioSelectWrapper = styled.div`
  display: flex;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
`;

export const RadioInput = styled.input`
  margin-right: 8px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
  outline: none;
  &:checked {
    border-color: ${uppColourPalette.navy[500]};
  }
  &:checked::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${uppColourPalette.navy[500]};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface RadioSelectorProps {
  options: string[];
  onChange: (value: string) => void;
  defaultValue?: string;
}

export const RadioSelect = ({ options, onChange, defaultValue }: RadioSelectorProps) => {
  return (
    <RadioSelectWrapper>
      {options.map((option) => (
        <Label key={option}>
          <RadioInput
            type="radio"
            name={option}
            value={option}
            checked={option === defaultValue}
            onChange={() => onChange(option)}
          />
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </Label>
      ))}
    </RadioSelectWrapper>
  );
};
