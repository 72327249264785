import styled from "styled-components";
import { ChangeLogItem } from "../../api/changelog";
import { GetCurrencySymbol } from "../../util/currency";
import { ChangeLogType } from "./Changelog";
import { uppColourPalette } from "../../util/colours";
import { useState } from "react";
import JsonFormatPopover from "./JsonFormatPopover";

export const Cell = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
`;

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 390px 60px;
  @media screen and (min-width: 1800px) {
    grid-template-rows: 60px 850px 60px;
  }
`;

export const Header = styled.div`
  padding: 0 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${uppColourPalette.darkGreen[500]};
  font-family: Jost;
  color: white;
`;

export const RowWrapper = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid ${uppColourPalette.darkGreen[500]};
`;

export const Footer = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: Jost;
  background: ${uppColourPalette.darkGreen[500]};
  box-shadow: 0 -2px 2px rgba(123, 119, 238, 0.1);
  border-radius: 0 0 4px 4px;
`;

const StyledRow = styled(Row)`
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 1fr;
`;

const StyledHeader = styled(Header)`
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 1fr;
`;

const StyledFooter = styled(Footer)`
  justify-content: center;
  gap: 12px;
`;

const StyledWrapper = styled(TableWrapper)`
  @media screen and (min-width: 1024px) {
    grid-template-rows: 60px 400px 60px;
  }
  @media screen and (min-width: 1400px) {
    grid-template-rows: 60px 700px 60px;
  }
  @media screen and (min-width: 2000px) {
    grid-template-rows: 60px 1000px 60px;
  }
`;

const ClickableCell = styled.p`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CopiedMessage = styled.p`
  font-size: small;
  color: ${uppColourPalette.darkGreen[400]};
`;

const OnCellClick = ({ value }: { value: string[] }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(value.join(", "));
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div onClick={handleClick}>
      {isCopied ? <CopiedMessage>Copied to clipboard!</CopiedMessage> : <ClickableCell>Copy Sku List</ClickableCell>}
    </div>
  );
};

const flaggedProduct = (title: string) => {
  return {
    title: title,
    format: (value: string[]): JSX.Element | string => {
      if (value.length > 1) {
        return <OnCellClick value={value} />;
      } else {
        return value.length === 1 ? value[0] : "N/A";
      }
    },
  };
};

const changeLogConfig = {
  targetCpa: {
    title: "Target Cpa",
    format: (value: string | number): string => {
      return `${GetCurrencySymbol()}${value}`;
    },
  },
  targetRoas: {
    title: "Target ROAS",
    format: (value: number): string => {
      return `${value * 100}%`;
    },
  },
  targetSpend: {
    title: "Target Spend",
    format: (value: string | number): string => {
      return `${GetCurrencySymbol()}${value}`;
    },
  },
  strategyChanged: {
    title: "Strategy Changed",
    format: (value: string): string => {
      return value;
    },
  },
  strategyCancelled: {
    title: "Strategy Cancelled",
    format: (value: string): string => {
      return value;
    },
  },
  automation_budget_zero_non_default: {
    title: "Visibility budget (zero data)",
    format: (value: number): string => {
      return `${value * 100}%`;
    },
  },
  automation_budget_zero_with_orders_non_default: {
    title: "Visibility budget (with orders)",
    format: (value: number): string => {
      return `${value * 100}%`;
    },
  },
  intakeMargin_targetContributionMarginDefaultPercentage: {
    title: "MPA target change",
    format: (value: number): string => {
      return `${value * 100}%`;
    },
  },
  blocked: flaggedProduct("Blocked Products"),
  unblocked: flaggedProduct("Unblocked Products"),
  boosted: flaggedProduct("Boosted Products"),
  unboosted: flaggedProduct("Unboosted Products"),
  locked: flaggedProduct("Locked Products"),
  unlocked: flaggedProduct("Unlocked Products"),
  categoryLocked: flaggedProduct("Locked Categories"),
  categoryUnlocked: flaggedProduct("Unlocked Categories"),
  categoryTargetRoasOverride: {
    title: "Category Target Roas Override",
    format: (value: string): string => {
      return value;
    },
  },
  mpaUpload: {
    title: "MPA Uploads",
    format: (value: string): string => {
      return value;
    },
  },
  targetRoasOverrideSet: {
    title: "Target Roas Override",
    format: (value: string): string => {
      return value;
    },
  },
  cogsSet: {
    title: "COGS",
    format: (value: string): string => {
      return value;
    },
  },
  tcmSet: {
    title: "TCM",
    format: (value: string): string => {
      return value;
    },
  },
  ignoreProductsCustomLabelMapping: {
    title: "Custom Label Exlusions",
    format: (value: string): string => {
      return (JSON.parse(value) as [])
        .map(
          (item: { customLabelKey: string; customLabelValue: string }) =>
            `${item?.customLabelKey}: ${item?.customLabelValue}`
        )
        .join(", ");
    },
  },
  uppWorkflowCreated: {
    title: "created",
    format: (value: string): JSX.Element => {
      return <JsonFormatPopover>{JSON.parse(value)}</JsonFormatPopover>;
    },
  },
  uppWorkflowUpdated: {
    title: "updated",
    format: (value: string): JSX.Element => {
      return <JsonFormatPopover>{JSON.parse(value)}</JsonFormatPopover>;
    },
  },
  uppWorkflowDeleted: {
    title: "deleted",
    format: (value: string): JSX.Element => {
      return <JsonFormatPopover>{JSON.parse(value)}</JsonFormatPopover>;
    },
  },
};

const ChangeLogTable = (props: {
  children: any;
  page: number;
  changeLogType: ChangeLogType;
  changeLogs: ChangeLogItem[];
}) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        <Cell>Date</Cell>
        <Cell>Change</Cell>
        <Cell>Prev. Value</Cell>
        <Cell>Value</Cell>
        <Cell>User</Cell>
      </StyledHeader>
      <RowWrapper>
        {props.changeLogs.map((changelogItem) => (
          <StyledRow key={props.changeLogs.indexOf(changelogItem)}>
            <Cell>
              <CellContent>{new Date(changelogItem.date).toUTCString()}</CellContent>
            </Cell>
            <Cell>
              <CellContent>
                {changeLogConfig[changelogItem.changeType]
                  ? changeLogConfig[changelogItem.changeType].title
                  : changelogItem.changeType}
              </CellContent>
            </Cell>
            <Cell>
              <CellContent>
                {changelogItem.previousValue !== null && changelogItem.previousValue !== undefined
                  ? changeLogConfig[changelogItem.changeType]?.format(changelogItem.previousValue as any)
                  : "N/A"}
              </CellContent>
            </Cell>
            <Cell>
              {changeLogConfig[changelogItem.changeType] ? (
                <CellContent>
                  {changeLogConfig[changelogItem.changeType].format(changelogItem.change as any)}
                </CellContent>
              ) : (
                <CellContent>{changelogItem.changeType}</CellContent>
              )}
            </Cell>
            <Cell>
              <CellContent>{changelogItem.user}</CellContent>
            </Cell>
          </StyledRow>
        ))}
      </RowWrapper>

      <StyledFooter>{props.children}</StyledFooter>
    </StyledWrapper>
  );
};

export default ChangeLogTable;
