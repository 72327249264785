import { Box } from "@material-ui/core";
import React from "react";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { LinkTray } from "./LinkTray";

export interface LinkItem {
  title: string;
  url: string;
  category: string;
}
export const UsefulLinks = () => {
  const links: Array<LinkItem> = [
    {
      title: "File Sharing - Google Drive",
      url: "https://drive.google.com/drive/u/2/folders/0AEa-iC9UpGc_Uk9PVA",
      category: "company",
    },
    {
      title: "Monitoring - Datadog",
      url: "https://app.datadoghq.eu/",
      category: "engineering",
    },
    {
      title: "Authentication - Auth0",
      url: "https://manage.auth0.com/dashboard/eu/uppai/",
      category: "engineering",
    },
    {
      title: "Upp - Dashboard",
      url: "https://dashboard.upp.ai",
      category: "internal",
    },
    {
      title: "Upp - Reporting (Metabase)",
      url: "https://reporting.upp.ai/",
      category: "internal",
    },
    {
      title: "Documentation & Diagraming - Miro",
      url: "https://miro.com/app/",
      category: "company",
    },
    {
      title: "Bamboo HR",
      url: "https://uppai.bamboohr.com/home/",
      category: "company",
    },
    {
      title: "Temporal",
      url: "https://temporal.io",
      category: "engineering",
    },
    {
      title: "Circle CI",
      url: "https://circleci.com",
      category: "engineering",
    },
    {
      title: "SonarCloud",
      url: "https://sonarcloud.io/login",
      category: "engineering",
    },
  ];

  return (
    <Box
      sx={{
        bgcolor: "white",
        minHeight: "100%",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <StyledDashboardContainer>
        <LinkTray links={links.filter((item) => item.category === "internal")} title="Internal" />
        <LinkTray links={links.filter((item) => item.category === "company")} title="Company" />
        <LinkTray links={links.filter((item) => item.category === "engineering")} title="Engineering" />
      </StyledDashboardContainer>
    </Box>
  );
};

export default UsefulLinks;
