interface Option {
  displayValue: string;
  value: string;
}
interface DropdownProps {
  options: Option[];
  selected: string;
  handleSelection: (selected: string) => void;
}
const Dropdown = ({ options, selected, handleSelection }: DropdownProps) => {
  return (
    <select style={{ width: "180px" }} value={selected || ""} onChange={(e) => handleSelection(e.target.value)}>
      <option value="" disabled></option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.displayValue}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
